import React, { useContext, useEffect, useState } from "react"
import { Route, Redirect, Switch } from "react-router-dom"
import slugify from "slug"
import NewNavigationBar from "./topbar/NewNavigationBar"
import SlideModal from "./modals/SlideModal"
import PricingModal from "./modals/PricingModal"
import MainModal from "./modals/MainModal"
import CartContainer from "./cart/CartContainer"
import { NotFoundPage } from "./NotFoundPage"
import BatchesContainer from "./containers/BatchesContainer"
import DashboardContainer from "./containers/DashboardContainer"
import Users from "./admin/Users"
import AdminPage from "./admin/AdminPage"
import ProfilePage from "./profile/ProfilePage"
import { PricingPage } from "./pricing/PricingPage"
import PayPalRedirect from "./pricing/PayPalRedirect"
import AdminInfoBar from "./admin/AdminInfoBar"
import { LoadingPlaceholder, TrialBar } from "./utils/placeholders"
import LogsPage from "./logs"
import { MainModalContext } from "../hooks/MainModalHook"
import { SlideModalContext } from "../hooks/SlideModalHook"
import { PricingModalContext } from "../hooks/PricingModalHook"
import { AuthContext } from "../hooks/AuthHook"
import { NotificationContext } from "../hooks/NotificationHook"
import { Notification } from "./utils/notifications"
import Category from "./category"
import { UserDataContext, UserDataContextProvider } from "../hooks/UserDataHook"
import { CategoryContext, CategoryContextProvider } from "@/context/CategoryContext"
import SearchContainer from "./containers/SearchContainer"
import FavouritesContainer from "./containers/FavouritesContainer"
import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { UserData } from "../graphql/queries"
import BatchDetailsPage from "./containers/DashboardContainer/BatchDetailsPage"
import { useIsInsidePowerPoint } from "../context/IsInsidePowerPointContext"
import PowerPointNavbar, { GET_UPDATED_SLIDES } from "./topbar/PowerPointNavbar"
import RecentSearches from "./containers/RecentSearches"
import { deleteOutdatedSlide, getUpdatedSlides, insertSlideInPowerPoint } from "@/components/utils/powerpoint/add-in"
import { NotificationIcon } from "@/svg/SvgIcons"

const SLIDE_DOWNLOAD_URL = gql`
  query slideDownloadUrl($slideId: String!, $presentationName: String!) {
    slideDownloadUrl(slideId: $slideId, presentationName: $presentationName)
  }
`

const MainPage = () => {
  const { user, sessionOwner } = useContext(UserDataContext)
  const { categories } = useContext(CategoryContext)
  const { subscription, group, teamOwner, cart } = user
  const [searchInputValue, setSearchInputValue] = useState("")
  const isInsidePowerPoint = useIsInsidePowerPoint()
  const [updatedSlides, setUpdatedSlides] = useState([])
  const [fetchUpdatedSlides] = useLazyQuery(GET_UPDATED_SLIDES, {
    context: { isUsingNewScApi: true },
    fetchPolicy: "network-only"
  })
  const [getSlideDownloadUrl] = useLazyQuery(SLIDE_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    context: { isUsingNewScApi: true }
  })

  const handleOutdatedSlides = async () => {
    if (isInsidePowerPoint) {
      const slides = await getUpdatedSlides(fetchUpdatedSlides)
      if (slides) {
        setUpdatedSlides(slides)
      }
    }
  }

  useEffect(() => {
    if (isInsidePowerPoint) {
      handleOutdatedSlides()
      Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, handleOutdatedSlides)
      return () => {
        Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, {
          handler: handleOutdatedSlides
        })
      }
    }
  }, [isInsidePowerPoint])

  const categoryName = {
    SLIDES: group.groupId === "latimes" ? "Core Decks" : "Slides",
    TEAM_PRESENTATIONS: group.groupId === "latimes" ? "Opportunities and Packages" : "Team Presentations"
  }

  const getCategoryByName = (name) => {
    return categories.find((category) => category.name === name)
  }

  const getPresentationFromCategory = (category, presentationId) => {
    if (!category || !presentationId) {
      return null
    }
    return category.presentations.find((presentation) => presentation.id === presentationId)
  }

  const handleUpdateOutdatedSlides = async () => {
    try {
      await window.PowerPoint.run(async function (context) {
        const slides = context.presentation.slides
        slides.load("tags/key, tags/value")
        await context.sync()
        const uniqueSlideUrls = {}
        for (const updatedSlide of updatedSlides) {
          await deleteOutdatedSlide(updatedSlide.index)
          const targetSlide =
            updatedSlide.index > 0 ? context.presentation.slides.getItemAt(updatedSlide.index - 1) : null
          targetSlide?.load("id")
          await context.sync()
          const urlKey = `${updatedSlide.id}/${updatedSlide.phash}`
          if (!uniqueSlideUrls[urlKey]) {
            const { data } = await getSlideDownloadUrl({
              variables: {
                slideId: updatedSlide.id,
                presentationName: ""
              }
            })
            uniqueSlideUrls[urlKey] = data.slideDownloadUrl
          }
          await insertSlideInPowerPoint(uniqueSlideUrls[urlKey], targetSlide?.id)
        }
      })
      setUpdatedSlides([])
    } catch (error) {
      console.error("Error:  running add-in" + error)
      throw error
    }
  }

  if (isInsidePowerPoint === null) {
    return <LoadingPlaceholder />
  }

  return (
    <div className={`${isInsidePowerPoint ? "bg-[#F5F5F5] flex flex-col min-h-screen" : ""}`}>
      {!!sessionOwner && <AdminInfoBar />}
      {subscription && group.groupId === "management" && subscription.plan.planId === "trial" && teamOwner && (
        <TrialBar />
      )}
      <PayPalRedirect />
      {isInsidePowerPoint ? (
        <PowerPointNavbar searchInputValue={searchInputValue} setSearchInputValue={setSearchInputValue} />
      ) : (
        <NewNavigationBar />
      )}
      <div
        className={`${isInsidePowerPoint ? `flex-1 flex w-full ${isInsidePowerPoint ? "flex-col" : ""}` : ""}`}
        data-testid="cart-wrapper"
      >
        {isInsidePowerPoint &&
          (process.env.NODE_ENV === "development" ||
            (process.env.NODE_ENV === "production" && group.groupId === "demo")) && (
            <div className="w-full desktop-big:w-[1812px]">
              <div className="p-[20px]">
                <h2 className="text-[9px] text-[#717171] uppercase mb-[15px]">TOOLS</h2>
                <div className="bg-white p-[5px] rounded-[4px] flex items-center justify-between">
                  <div className="flex items-center gap-1">
                    <span className={`${updatedSlides.length ? "text-sc-blue" : "text-inherit"} flex items-center`}>
                      <NotificationIcon />
                    </span>
                    <span>
                      {!updatedSlides.length
                        ? "All slides are up to date"
                        : `There ${updatedSlides.length === 1 ? "is" : "are"} ${updatedSlides.length} outdated slide${
                            updatedSlides.length > 1 ? "s" : ""
                          }`}
                    </span>
                  </div>
                  {!!updatedSlides.length && (
                    <button className="text-sc-blue" onClick={handleUpdateOutdatedSlides}>
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        <div
          className={`${isInsidePowerPoint ? "w-full" : ""} ${
            cart && !isInsidePowerPoint ? "mr-[312px] min-[2050px]:mr-[402px]" : ""
          }`}
        >
          <Switch>
            <Route component={BatchDetailsPage} path="/dashboard/:urlToken/:name/:slideId?" />
            <Route exact path="/dashboard" render={() => <DashboardContainer />} />
            <Route
              path="/assets/:id"
              render={(props) => {
                const assetId = props.match.params.id
                const category = getCategoryByName(categoryName.SLIDES)
                const presentation = getPresentationFromCategory(category, assetId)

                if (presentation) {
                  const path = `/library/${presentation.urlToken}/${slugify(presentation.name)}`
                  return <Redirect from={`/assets/${assetId}`} to={path} />
                }
              }}
            />
            {/* <Route component={BatchesContainer} path="/shared" /> */}
            {/*<Route*/}
            {/*  path="/shared"*/}
            {/*  render={() => {*/}
            {/*    const category = getCategoryByName(categoryName.TEAM_PRESENTATIONS)*/}

            {/*    if (category) {*/}
            {/*      const categoryPath = `/library/${category.urlToken}/${slugify(category.name)}`*/}
            {/*      return <Redirect from="/shared" to={categoryPath} />*/}
            {/*    }*/}
            {/*  }}*/}
            {/*/>*/}
            {/* <Route component={BatchesContainer} path="/presentations" /> */}
            <Route component={BatchesContainer} path="/my-presentations/:urlToken?/:name?/:slideId?" />
            <Redirect from="/presentations" to="/my-presentations" />
            {/* <Route path="/templates" render={(props) => <Batches rest={props} />} /> */}
            <Route
              path="/templates"
              render={() => {
                const category = getCategoryByName(categoryName.SLIDES)

                if (category) {
                  const categoryPath = `/library/${category.urlToken}/${slugify(category.name)}`
                  return <Redirect from="/templates" to={categoryPath} />
                }
              }}
            />
            <Route component={FavouritesContainer} path="/favorites" />
            <Route
              path="/search/recent"
              render={(props) => <RecentSearches {...props} setSearchInputValue={setSearchInputValue} />}
            />
            <Route component={SearchContainer} path="/search" />
            <Route component={PricingPage} path="/pricing" />
            <Route component={Users} path="/admin-panel/users" />
            <Route component={AdminPage} path="/admin-panel" />
            {/* FIX: does not work, is this even used? */}
            <Route component={ProfilePage} path="/myprofile" />
            <Route component={LogsPage} path="/activity" />
            <Route component={Category} path="/library/:urlToken?/:name?" />
            <Redirect from="/collections" to="/presentations" />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
        {cart && !isInsidePowerPoint && <CartContainer />}
      </div>
    </div>
  )
}

const MainPageContainer = () => {
  const { isAuthenticated } = useContext(AuthContext)
  const { modalContent, modalData, modalType, modalCloseable } = useContext(MainModalContext)
  const { showModal } = useContext(SlideModalContext)
  const { modalOpened, modalComponent, selectedPlan, paymentStage, stripeStage, closeModal, setSelectedPlan } =
    useContext(PricingModalContext)
  const { type, content } = useContext(NotificationContext)

  if (!isAuthenticated) {
    return <Redirect to="/users/sign_in" />
  }

  const { loading } = useQuery(UserData, {
    fetchPolicy: "cache-and-network"
  })

  if (loading) {
    return <div />
  }

  return (
    <UserDataContextProvider>
      <CategoryContextProvider>
        <MainModal
          closeable={modalCloseable}
          content={modalContent}
          data={modalData}
          isOpen={!!modalContent}
          type={modalType}
        />
        <SlideModal showModal={showModal} />
        <PricingModal
          closeModal={closeModal}
          modalComponent={modalComponent}
          modalOpened={modalOpened}
          paymentStage={paymentStage}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          stripeStage={stripeStage}
        />
        <Notification type={type}>{content}</Notification>
        <Route component={MainPage} path="/" />
      </CategoryContextProvider>
    </UserDataContextProvider>
  )
}

export default MainPageContainer
