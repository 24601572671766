import { gql, useMutation, useQuery } from "@apollo/client"
import { useContext, useRef } from "react"
import { Link } from "react-router-dom"
import { NotificationContext } from "@/hooks/NotificationHook"
import NotificationPopUp from "../notifications/NotificationPopUp"
import { UserDataContext } from "@/hooks/UserDataHook"
import { CategoryContext } from "@/context/CategoryContext"
import { MERGE_SLIDES } from "@/graphql/mutations"
import { useForm } from "@/hooks/FormHook"
import { isRequired } from "@/components/utils/helpers"

const MY_PRESENTATION = gql`
  query myBatches {
    myBatches {
      _id
      id
      thumbUrl
      name
      batchId
      icon
      isFavourite
      labels
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        downloadUrl
        linksDataHeight
        linksDataWidth
        state
        isFavourite
      }
      category {
        _id
      }
    }
  }
`

const NotificationContent = () => (
  <p className="cursor-pointer">
    Presentation saved successfully. Click <Link to={"/my-presentations"}>here</Link> to preview.
  </p>
)

const CartModalForm = ({ closeAfterSave, closeModal, slides, refetchAfterSave }) => {
  const { open } = useContext(NotificationContext)
  const { updateSlidesInCartFunction } = useContext(UserDataContext)
  const { addPresentation } = useContext(CategoryContext)
  const [values, onChange] = useForm([
    {
      name: "name",
      validators: [isRequired]
    }
  ])

  const [mergeSlidesForCreation] = useMutation(MERGE_SLIDES, {
    context: { isUsingNewScApi: true }
  })
  const { refetch } =
    refetchAfterSave &&
    useQuery(MY_PRESENTATION, {
      fetchPolicy: "network-only",
      context: { isUsingNewScApi: true }
    })

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { name } = values

    const slidesInput = slides.map((item) => {
      const { blueprintId } = item
      return { blueprintId }
    })

    const {
      data: { createPresentation }
    } = await addPresentation({
      variables: { name, slides: slidesInput, isMyPresentation: true }
    })
    if (createPresentation.success) {
      refetch && (await refetch())
      await mergeSlidesForCreation({ variables: { id: createPresentation.presentation.id } })
    }

    localStorage.setItem("cartIsSaved", `${true}`)

    if (closeAfterSave) {
      await updateSlidesInCartFunction([])
    }

    closeModal()

    open({
      type: "success",
      duration: 5,
      content: (
        <NotificationPopUp>
          <NotificationContent />
        </NotificationPopUp>
      )
    })
  }

  return (
    <div className="sc-modal-content">
      <div className="sc-modal-header">
        <h1>Name presentation</h1>
        <button className="close-modal" onClick={closeModal}>
          <svg version="1.1" viewBox="0 0 14 14" x="0px" y="0px">
            <path
              className="close-icon-fill"
              d="M7.7,7l6.1-6.1c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0L7,6.3L0.9,0.1C0.7,0,0.3,0,0.1,0.1
              S0,0.7,0.1,0.9L6.3,7l-6.1,6.1c-0.2,0.2-0.2,0.5,0,0.7C0.2,14,0.4,14,0.5,14s0.3,0,0.4-0.1L7,7.7l6.1,6.1c0.1,0.1,0.2,0.1,0.4,0.1
              s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L7.7,7z"
            />
          </svg>
        </button>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <label>Name</label>
          <input
            className="collection-name-input"
            name="name"
            onChange={onChange}
            placeholder="Presentation name"
            type="text"
            value={values.name}
          />
          <div className="sc-modal-buttons">
            <button className="btn-gray-blue" onClick={closeModal} type="button">
              Cancel
            </button>
            <button className="btn-dark-blue" disabled={!values.name.trim()} type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CartModalForm
