import { createContext, useEffect, useState } from "react"
import { User } from "../graphql/types/queries/user"
import { gql, useMutation, useQuery } from "@apollo/client"
import { UserData } from "@/graphql/queries"
import { updateSlidesInCart as updateSlidesInCartMutation } from "@/graphql/mutations"
import { Slide } from "@/graphql/types/queries"

const CHANGE_EDIT_MODE = gql`
  mutation changeEditMode($state: Boolean!) {
    changeEditMode(state: $state) {
      success
      code
      message
    }
  }
`

const GET_CART = gql`
  query cart {
    cart {
      id
      name
      batchId
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        isFavourite
        downloadUrl
      }
    }
  }
`

export const changeCartVisibility = (visible: boolean) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: "scCartVisibilityChange", visible })
}

interface UseUserDataContext {
  authToken: string
  sessionOwner: any
  user: User
  updateSlidesInCartFunction: (slides: Slide[]) => Promise<void>
  handleEditModeChange: (state: boolean) => Promise<void>
}

export const UserDataContext = createContext<UseUserDataContext>({} as UseUserDataContext)

export const UserDataContextProvider = ({ children }: any) => {
  const { data } = useQuery(UserData, {
    fetchPolicy: "cache-and-network"
  })
  const { session } = data
  const { user, authToken, sessionOwner } = session
  const [userData, setUserData] = useState(user)

  const [updateSlidesInCart] = useMutation(updateSlidesInCartMutation, {
    context: { isUsingNewScApi: true }
  })

  const { data: cartData, loading: cartLoading } = useQuery(GET_CART, {
    context: { isUsingNewScApi: true }
  })

  const [changeEditMode] = useMutation(CHANGE_EDIT_MODE, {
    context: { isUsingNewScApi: true }
  })

  useEffect(() => {
    if (!cartLoading && cartData) {
      setUserData({ ...userData, cart: cartData.cart })
    }
  }, [cartLoading, cartData])

  const updateSlidesInCartFunction = async (slides: Slide[]) => {
    if (slides.length) {
      localStorage.setItem("cartIsSaved", `${false}`)
    }
    const mappedSlides = slides.map((item) => {
      const { blueprintId } = item
      return {
        blueprintId
      }
    })
    const updatedUserData = {
      ...userData,
      cart: slides.length
        ? {
            name: "Slides",
            batchId: `unsaved-${userData.userId}`,
            ...userData.cart,
            slides
          }
        : null
    }
    setUserData(updatedUserData) // Set the state with optimistic response

    await updateSlidesInCart({
      mutation: updateSlidesInCartMutation,
      variables: {
        slides: mappedSlides
      },
      update: (cache, { data }) => {
        cache.writeQuery({
          query: GET_CART,
          data: { cart: data.updateSlidesInCart.cart }
        })
      },
      optimisticResponse: {
        updateSlidesInCart: {
          presentation: updatedUserData.cart
        }
      },
      onCompleted: ({ updateSlidesInCart }) => {
        changeCartVisibility(!!updateSlidesInCart.cart)
      }
    })
  }

  const handleEditModeChange = async (state: boolean) => {
    await changeEditMode({
      variables: { state },
      update: () => {
        setUserData({ ...userData, isEditModeActive: state })
      }
    })
  }

  return (
    <UserDataContext.Provider
      value={{
        user: userData,
        authToken,
        sessionOwner,
        updateSlidesInCartFunction,
        handleEditModeChange
      }}
    >
      {children}
    </UserDataContext.Provider>
  )
}
