import { CategoryIcon } from "@/svg/CategoryIcons"
import ContextMenu from "@/components/presentation/ContextMenu"
import { useContext, useState } from "react"
import { UserDataContext } from "@/hooks/UserDataHook"
import { useIsInsidePowerPoint } from "@/context/IsInsidePowerPointContext"

const CollectionPresentation = ({ batch, handleClick, refetch, type, active, activeItem, isSearchPage }) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const { user } = useContext(UserDataContext)
  const isInsidePowerPoint = useIsInsidePowerPoint()
  const isDashboardPage = location.pathname === "/dashboard"

  return isSearchPage ? (
    <div
      className={`h-full group bg-white transition-shadow duration-200 ease-in-out text-[#829FC0] hover:text-sc-blue hover:!shadow-[0_0_0_2px] hover:!shadow-sc-blue ${
        isInsidePowerPoint ? "rounded-[4px]" : "shadow-[0_0_0_1px] rounded-[3px]"
      } ${active ? "shadow-[0_0_0_2px] shadow-sc-blue" : "shadow-sc-line"}`}
    >
      <div
        className={`h-full ${
          isInsidePowerPoint
            ? isDashboardPage || isSearchPage
              ? "p-[10px] flex flex-col gap-[8px]"
              : "p-[15px] flex flex-col gap-[20px]"
            : "p-[21px] grid min-[450px]:grid-cols-[10.8%_2fr] tablet-md:grid-cols-none tablet-xl:grid-cols-[10.8%_2fr] gap-[25px]"
        }`}
        onClick={handleClick}
      >
        <div
          className={`hidden min-[450px]:block tablet-md:hidden tablet-xl:block ${
            isInsidePowerPoint ? "min-[300px]:block" : ""
          }`}
          data-testid="collection-icon"
        >
          {isInsidePowerPoint ? (
            <CategoryIcon categoryIcon={"add-in sample"} />
          ) : (
            <CategoryIcon categoryIcon={batch.icon} />
          )}
        </div>
        <div className="flex flex-col gap-[7px]">
          <h2
            className={`line-clamp-1 text-sc-text-dark font-bold ${
              isInsidePowerPoint ? "text-[12px] font-semibold" : ""
            }`}
            data-testid="collection-name"
          >
            {batch.name}
          </h2>
          <div
            className={`flex gap-[7px] items-center whitespace-nowrap ${
              isInsidePowerPoint ? "text-[11px] text-[#717171]" : "text-[13px] text-[#A5A8AF]"
            }`}
          >
            {(!isInsidePowerPoint || (!isDashboardPage && !isSearchPage)) && (
              <>
                <span>{batch.slides.length} slides</span>
                <div
                  className={`hidden tablet-md:hidden tablet-xl:flex items-center ${
                    isInsidePowerPoint ? "min-[350px]:flex" : "min-[450px]:flex"
                  } `}
                >
                  <svg fill="none" height="4" viewBox="0 0 4 4" width="4" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2.06494" cy="2" fill="#A5A8AF" r="1.5" />
                  </svg>
                  <span className="ml-[7px] whitespace-normal line-clamp-1">
                    {" "}
                    {user.userId === batch.creator.id ? "You" : `${batch.creator.firstName} ${batch.creator.lastName}`}
                  </span>
                </div>
                <svg fill="none" height="4" viewBox="0 0 4 4" width="4" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2.06494" cy="2" fill="#A5A8AF" r="1.5" />
                </svg>
              </>
            )}
            <span>
              {new Date(batch.updatedAt).toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
                year: "numeric"
              })}
            </span>
          </div>
        </div>
      </div>

      {!activeItem && batch.urlToken && !isInsidePowerPoint && (
        <div className="w-[32px] h-[24px] absolute top-[10px] right-[4px] flex items-center justify-center px-[8px] py-[4px]">
          <ContextMenu
            batch={batch}
            getSlidesAndLock={undefined}
            isOpened={isDropdownOpened}
            isSearchPage={isSearchPage}
            refetch={refetch}
            setIsOpened={setIsDropdownOpened}
            setModalIsOpened={undefined}
            type={type}
          />
        </div>
      )}
    </div>
  ) : (
    <div
      className={`h-full group bg-white rounded-[3px] transition-shadow duration-200 ease-in-out text-[#829FC0] shadow-[0_0_0_1px] hover:text-sc-blue hover:!shadow-[0_0_0_2px] hover:!shadow-sc-blue ${
        active ? "shadow-[0_0_0_2px] shadow-sc-blue" : "shadow-sc-line"
      }`}
    >
      <div className={"h-full flex flex-col"} onClick={handleClick}>
        <div className="h-[49.5%] flex justify-center items-end">
          <div className="w-[25.6%] aspect-[46/51] flex" data-testid="collection-icon">
            <CategoryIcon categoryIcon={batch.icon} />
          </div>
        </div>
        <div className="h-[50.5%] p-[9.9%] w-full text-center font-bold text-[#0E2642] text-[15px]/[20px]">
          <h2
            className="h-full max-h-[2.9em] line-clamp-2 mobile-sm:max-h-[2.9em] mobile-sm:line-clamp-2 min-[1170px]:max-desktop-lg:max-h-[4em] min-[1170px]:max-desktop-lg:line-clamp-3 min-[1570px]:max-desktop-2xl:max-h-[4em] min-[1570px]:max-desktop-2xl:line-clamp-3 min-[1770px]:max-desktop-3xl:max-h-[4em] min-[1770px]:max-desktop-3xl:line-clamp-3 desktop-big:max-h-[4em] desktop-big:line-clamp-3"
            data-testid="collection-name"
          >
            {batch.name}
          </h2>
        </div>
      </div>

      {!activeItem && batch.urlToken && (
        <ContextMenu
          batch={batch}
          getSlidesAndLock={undefined}
          isOpened={isDropdownOpened}
          refetch={refetch}
          setIsOpened={setIsDropdownOpened}
          setModalIsOpened={undefined}
          type={type}
        />
      )}
    </div>
  )
}

export default CollectionPresentation
